import { defineMessages, useIntl } from 'react-intl';
import { Button } from '@components/atoms/button/button';
import { IconCalendar } from '@components/atoms/icons';
import { TextUppercase } from '@components/atoms/text-uppercase/text-uppercase';
import { Text } from '@components/atoms/text/text';
import {
  EnvelopeOfferingOfTheWeek as EnvelopeOfferingOfTheWeekApi,
  PaymentCategory,
} from '@models/payment-category';

const messages = defineMessages({
  offeringOfTheWeek: {
    id: 'fzemci',
    defaultMessage: 'Offering of the week',
    description: 'Label shown on the envelope page.',
  },
  localChurchBudget: {
    id: '/pDO/n',
    defaultMessage: 'Go to Local Church Budget',
    descriotion: 'Label for Local Church Budget',
  },
  goTo: {
    id: 'WgJ9hD',
    defaultMessage: 'Go to {category}',
    description:
      'CTA on enelope that links to the offering of the week category',
  },
  goToLocalChurch: {
    defaultMessage: 'Go to local church budget',
    id: 'YPYyZz',
    description: 'CTA on envelope that links to local church section',
  },
  goToConferenceBudget: {
    defaultMessage: 'Go to conference / union budget',
    id: 'ChWyUC',
    description: 'CTA on envelope that links to conference / union section',
  },
  goToWorldBudget: {
    defaultMessage: 'Go to world budget',
    id: 'kGTC/q',
    description: 'CTA on envelope that links to world section',
  },
});

const MAX_CATEGORY_NAME_LENGTH = 35;

interface Props {
  item: EnvelopeOfferingOfTheWeekApi;
  category?: PaymentCategory;
  onCategoryClick: () => void;
  onScrollClick?: () => void;
}

const EnvelopeOfferingOfTheWeek = ({
  item,
  category,
  onCategoryClick,
  onScrollClick,
}: Props) => {
  const intl = useIntl();
  const mobileHiddenIfNoCategory = category ? '' : 'hidden sm:flex';
  const categoryName =
    category && category.name.length <= MAX_CATEGORY_NAME_LENGTH
      ? category.name
      : item.title;
  return (
    <div
      className={`${mobileHiddenIfNoCategory} w-full flex flex-col md:flex-row gap-4 bg-nad-alps-light border-nad-light-blue lg:!border-4 px-6 py-4 sm:px-9 sm:py-5`}
    >
      <div className="flex gap-2">
        <IconCalendar className="mt-0.5 sm:mt-0" width={22} height={23} />
        {category && (
          <Button
            onClick={onCategoryClick}
            type="button"
            className="sm:hidden !w-fit !lg:min-w-fit !p-0 !text-left"
            size="small"
            variant="link"
            icon="downArrow"
            iconVerticalPosition="top"
          >
            {intl.formatMessage(messages.offeringOfTheWeek)}: {categoryName}
          </Button>
        )}

        {!category && (
          <Button
            onClick={onScrollClick}
            type="button"
            className="sm:hidden !w-fit !lg:min-w-fit !p-0 !text-left"
            size="small"
            variant="link"
            icon="downArrow"
            iconVerticalPosition="top"
          >
            {intl.formatMessage(messages.offeringOfTheWeek)}:{' '}
            {intl.formatMessage(messages.localChurchBudget)}
          </Button>
        )}

        <TextUppercase className="hidden sm:block" size="S" color="nad-blue">
          {intl.formatMessage(messages.offeringOfTheWeek)}
        </TextUppercase>
      </div>
      <div className="hidden sm:flex flex-1 flex-col xl:flex-row items-start justify-between gap-2">
        <Text
          className={`${category ? 'xl:max-w-[600px]' : ''}`}
          size="S"
          color="nad-blue-2"
        >
          {item.summary}
        </Text>
        {category && (
          <Button
            onClick={onCategoryClick}
            type="button"
            className="!w-fit !lg:min-w-fit !p-0 !text-left !border-0"
            size="small"
            variant="link"
            icon="downArrow"
            iconVerticalPosition="top"
          >
            {intl.formatMessage(messages.goTo, {
              category: categoryName,
            })}
          </Button>
        )}

        {!category && (
          <Button
            onClick={onScrollClick}
            type="button"
            className="!w-fit !lg:min-w-fit !p-0 !text-left !border-0"
            size="small"
            variant="link"
            icon="downArrow"
            iconVerticalPosition="top"
          >
            {item.week_type === 'church'
              ? intl.formatMessage(messages.goToLocalChurch)
              : item.week_type === 'church_parent'
                ? intl.formatMessage(messages.goToConferenceBudget)
                : item.week_type === 'world'
                  ? intl.formatMessage(messages.goToWorldBudget)
                  : ''}
          </Button>
        )}
      </div>
    </div>
  );
};

export default EnvelopeOfferingOfTheWeek;
