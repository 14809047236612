import { FeaturedVideo } from './featured-video';

export const PAYMENT_CATEGORY_TYPE_OPTIONS = [
  'tithe',
  'church',
  'church.parent',
  'world',
] as const;

export type PaymentCategoryType =
  (typeof PAYMENT_CATEGORY_TYPE_OPTIONS)[number];

export interface PaymentCategory {
  id: string;
  name: string;
  description: string;
  photo_url: string;
  video_url: string;
  is_featured: boolean;
  sort_order: number | null;
  organization_id: string;
  payment_group_id: PaymentCategoryType;
}

export type PaymentCategoryStatus = 'active' | 'inactive';

export interface TreasurerPaymentCategory extends PaymentCategory {
  approved_by_canada: boolean;
  code: string;
  status: PaymentCategoryStatus;
  can_delete: boolean;
  pending_photo_url?: string;
  pending_video_url?: string;
}

export interface OrganizationRecommendedCode {
  code: string;
}

export interface OrganizationOverrideCode {
  id: string;
  code: string;
  payment_category_id: string;
  parent_organization_id: string;
}

export type PaymentCategoriesMap = {
  [key in PaymentCategoryType]: PaymentCategory[];
};

type PaymentCategoriesGroupCounts = {
  [key in PaymentCategoryType]: number;
};

export interface EnvelopeOfferingOfTheWeek {
  title: string;
  description: string;
  summary: string;
  for_sabbath: string;
  picture_url: string;
  payment_category_id?: string;
  week_type: string;
}

export interface PaymentCategories {
  payment_categories: PaymentCategoriesMap;
  group_counts: PaymentCategoriesGroupCounts;
  featured_videos: FeaturedVideo[];
  offering_of_the_week: EnvelopeOfferingOfTheWeek;
  highlighted_payment_category: PaymentCategory;
}
